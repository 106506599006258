import { useState, useEffect } from "react";

import Dialog from "@mui/material/Dialog";
import ImageList from "@mui/material/ImageList";

import CircularProgress from "@mui/material/CircularProgress";

import Image from "../components/Image";

import { getServerAPIBackend } from "../config";

function ImageGallery({ user, credits, setIsBuyCreditsDialogOpen }) {
  const [isLoadingImages, setIsLoadingImages] = useState(true);
  const [dialogOpenImage, setDialogOpenImage] = useState("");
  const [images, setImages] = useState([]);

  const isMobile = window.innerWidth < 600;
  const numCols = isMobile ? 2 : 5;

  // fetch images from backend API back end with user email parameter using form data
  useEffect(() => {
    if (user) {
      const fd = new FormData();
      fd.append("email", user.email);
      fetch(getServerAPIBackend() + "/get_user_images", {
        method: "POST",
        body: fd,
      })
        .then((res) => res.json())
        .then((data) => {
          setImages(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [user]);

  return (
    <div>
      {user ? (
        <div>
          {isLoadingImages && (
            <CircularProgress
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          )}
          <ImageList
            sx={{ margin: "5%" }}
            cols={numCols}
            rowHeight={"auto"}
            gap={8}
          >
            {images.map((image) => (
              <div>
                <Image
                  imageUrl={image}
                  setIsLoadingImages={setIsLoadingImages}
                  user={user}
                  setIsBuyCreditsDialogOpen={setIsBuyCreditsDialogOpen}
                  credits={credits}
                  setDialogOpenImage={setDialogOpenImage}
                ></Image>
                <Dialog
                  open={dialogOpenImage === image}
                  onClose={() => {
                    setDialogOpenImage("");
                  }}
                  fullWidth={true}
                >
                  <Image
                    imageUrl={image}
                    setIsLoadingImages={setIsLoadingImages}
                    user={user}
                    setIsBuyCreditsDialogOpen={setIsBuyCreditsDialogOpen}
                    credits={credits}
                    setDialogOpenImage={setDialogOpenImage}
                  />
                </Dialog>
              </div>
            ))}
          </ImageList>
        </div>
      ) : null}
    </div>
  );
}

export default ImageGallery;
