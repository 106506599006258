import { useRef, useState } from "react";

import { ReactSession } from "react-client-session";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Alert from "@mui/material/Alert";

import { getServerAPIBackend } from "../config";

const theme = createTheme();

export default function SignIn({ user, setUser }) {
  const [isAlertLoginFailedOpen, setIsAlertLoginFailedOpen] = useState(false);
  const refEmail = useRef(null);
  const refPassword = useRef(null);
  const handleSignIn = () => {
    const fd = new FormData();
    fd.append("email", refEmail.current.value);
    fd.append("password", refPassword.current.value);

    // display alert if login fails

    fetch(getServerAPIBackend() + "/sign_in_user", {
      method: "POST",
      body: fd,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.valid) {
          const userFetched = {
            userId: data.user_id,
            email: refEmail.current.value,
          };
          setUser(userFetched);
          ReactSession.set("user", userFetched);

          setIsAlertLoginFailedOpen(false);
        } else {
          setIsAlertLoginFailedOpen(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      {user ? null : (
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              <Box component="form" noValidate sx={{ mt: 1 }}>
                {isAlertLoginFailedOpen && (
                  <Alert severity="error">
                    The username or password entered are incorrect. Please try
                    again with the login provided to you.
                  </Alert>
                )}
                <TextField
                  inputRef={refEmail}
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Username"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={() => {
                    setIsAlertLoginFailedOpen(false);
                  }}
                />
                <TextField
                  inputRef={refPassword}
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={() => {
                    setIsAlertLoginFailedOpen(false);
                  }}
                />
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={handleSignIn}
                >
                  Sign In
                </Button>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      )}
    </div>
  );
}
