import { useRef, useState } from "react";
import { updateCredits } from "../api-product/Credits";
import { getServerAPIBackend } from "../config";

import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import DownloadIcon from "@mui/icons-material/Download";

function Image({
  user,
  credits,
  imageUrl,
  size,
  setIsLoadingImages,
  setIsBuyCreditsDialogOpen,
  setDialogOpenImage,
}) {
  const imageRef = useRef();
  const [downloadedImages, setDownloadedImages] = useState([]);

  const saveDownloadEvent = (url) => {
    const fd = new FormData();
    fd.append("user_id", user.userId);
    fd.append("image_url", url);
    fetch(getServerAPIBackend() + "/save_download_event", {
      method: "POST",
      body: fd,
    }).catch((err) => {
      console.log(err);
    });
  };

  function downloadFile(url, fileName) {
    let url_hd = url.replace("/wm/", "/hd/");
    fetch(url_hd, {
      method: "get",
    })
      .then((res) => res.blob())
      .then((blob) => blob.slice(0, blob.size, "image/png"))
      .then((res) => {
        const aElement = document.createElement("a");
        aElement.setAttribute("download", fileName);
        const href = URL.createObjectURL(res);
        aElement.href = href;
        aElement.setAttribute("target", "_blank");
        aElement.click();
        URL.revokeObjectURL(href);
      });
    saveDownloadEvent(url_hd);
  }

  const creditsCost = 5;
  // handle Download, if not enough credits, open buy credits dialog else download image
  const handleDownload = (url) => {
    const isAlreadyDownloaded = downloadedImages.includes(url);
    if (credits < creditsCost && !isAlreadyDownloaded) {
      setIsBuyCreditsDialogOpen(true);
    } else {
      downloadFile(url, "generated_image.png");
      setDownloadedImages([...downloadedImages, url]);
      !isAlreadyDownloaded && updateCredits(user?.userId, -creditsCost);
    }
  };
  return (
    <ImageListItem key={imageUrl}>
      <img
        src={`${imageUrl}`}
        srcSet={`${imageUrl}`}
        alt="Generated"
        loading="lazy"
        ref={imageRef}
        onLoad={() => {
          setIsLoadingImages(false);
        }}
        style={{ cursor: "pointer" }}
        onClick={() => {
          setDialogOpenImage(imageUrl);
        }}
        onContextMenu={(e) => {
          e.preventDefault();
        }}
      />
      <ImageListItemBar
        sx={{
          background: "rgba(0,0,0,0)",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "rgba(0,0,0,0.5)",
          },
        }}
        title={"Download"}
        position="bottom"
        actionIcon={<DownloadIcon sx={{ color: "white" }}></DownloadIcon>}
        actionPosition="left"
        onClick={() => handleDownload(imageUrl)}
      />
    </ImageListItem>
  );
}

export default Image;
