import Banner from "../components/Banner";
import ImageGallery from "../components/ImageGallery";
import "../styles/Layout.css";
import { useState } from "react";
import BuyCreditsDialog from "../components/BuyCreditsDialog";
import SignIn from "../components/SignIn";
import { ReactSession } from "react-client-session";
import Alert from "@mui/material/Alert";

ReactSession.setStoreType("localStorage");

function App() {
  const [isBuyCreditsDialogOpen, setIsBuyCreditsDialogOpen] = useState(false);
  const [credits, setCredits] = useState(0);
  const user_session = ReactSession.get("user");
  const [user, setUser] = useState(user_session);

  return (
    <div>
      <Banner
        user={user}
        setUser={setUser}
        credits={credits}
        setCredits={setCredits}
        setIsBuyCreditsDialogOpen={setIsBuyCreditsDialogOpen}
      />
      {!user && (
        <Alert severity="info" sx={{ alignContent: "center" }}>
          Send us your product photos and brief at{" "}
          <a href="mailto: design@presti.ai">design@presti.ai</a>. We’ll
          generate beautiful pictures and give you the access to your gallery.
        </Alert>
      )}
      <SignIn user={user} setUser={setUser} />
      <ImageGallery
        user={user}
        credits={credits}
        setIsBuyCreditsDialogOpen={setIsBuyCreditsDialogOpen}
      />
      <BuyCreditsDialog
        user={user}
        isBuyCreditsDialogOpen={isBuyCreditsDialogOpen}
        setIsBuyCreditsDialogOpen={setIsBuyCreditsDialogOpen}
      />
    </div>
  );
}

export default App;
