import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";

function BuyCreditsDialog({
  isBuyCreditsDialogOpen,
  setIsBuyCreditsDialogOpen,
  user,
}) {
  const handleClose = () => {
    setIsBuyCreditsDialogOpen(false);
  };
  return (
    <Dialog open={isBuyCreditsDialogOpen} onClose={handleClose}>
      <div className="dialog-layout" style={{ margin: "5%" }}>
        <h2>Buy 25 credits for 25€</h2>
        <p>With 25 credits you have 5 HD downloads (5 credits per download)</p>
        <DialogActions>
          <Button
            onClick={handleClose}
            href={
              user &&
              "https://buy.stripe.com/8wM2ai3TT845brWaEH?prefilled_email=" +
                user.email +
                "&client_reference_id=" +
                user.userId
            }
            target="_blank"
            variant="contained"
            sx={{ mr: 1 }}
            size="small"
          >
            Buy 25 Credits
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default BuyCreditsDialog;
