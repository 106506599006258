import { getServerAPIBackend } from "../config";

export const updateCredits = (userId, amount) => {
  const url = `${getServerAPIBackend()}/update_credits_gallery`;
  const fd = new FormData();
  fd.append("user_id", userId);
  fd.append("amount", amount);
  fetch(url, {
    method: "POST",
    body: fd,
  })
    .then((res) => res.json())
    .catch((err) => {
      console.log(err);
    });
};
