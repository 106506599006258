const API_URL_BACKEND = "https://presti-api-gallery-2pmm4u5lua-ez.a.run.app";
const API_URL_BACKEND_DEV = "http://localhost:8000";

export const getServerAPIBackend = () => {
  let SERVER;
  if (process.env.NODE_ENV === "production") {
    SERVER = API_URL_BACKEND;
  } else if (process.env.NODE_ENV === "development") {
    SERVER = API_URL_BACKEND_DEV;
  }
  return SERVER;
};
