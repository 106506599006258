import "../styles/Banner.css";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { React, useEffect } from "react";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import Chip from "@mui/material/Chip";
import { onSnapshot } from "firebase/firestore";
import { getUserDocumentRef } from "../firebase";
import { ReactSession } from "react-client-session";

function Banner({ user, setUser, credits, setCredits, setIsBuyCreditsDialogOpen }) {
  const initialCredits = 0;

  useEffect(() => {
    if (user) {
      const docRef = getUserDocumentRef(user.userId);
      const unsubscribe = onSnapshot(docRef, (snapshot) => {
        const data = snapshot.data();
        const fetchedCredits = data?.credits;
        setCredits(
          (fetchedCredits === null) | (fetchedCredits === undefined)
            ? initialCredits
            : fetchedCredits
        );
      });
      return () => {
        unsubscribe();
      };
    }
  }, [user, credits, setCredits]);

  const handleSignOut = () => {
    setUser(null);
    ReactSession.set("user", null);
  };

  return (
    <div className="banner">
      <div className="logo">
        <h1 className="banner-title">PRESTI 🪄</h1>
      </div>
      {user ? (
        <Stack className="menu" spacing={2} direction="row">
          {credits !== "undefined" ? (
            <Chip
              label={credits + " credits"}
              color="primary"
              variant="outlined"
              sx={{ margin: "auto", mr: "5px" }}
            />
          ) : null}
          <Button variant="outlined" onClick={handleSignOut} size="small">
            Log out
          </Button>
          <Button
            size="small"
            variant="contained"
            startIcon={<MonetizationOnIcon />}
            onClick={() => {
              setIsBuyCreditsDialogOpen(true);
            }}
          >
            Buy credits
          </Button>
        </Stack>
      ) : null}
    </div>
  );
}

export default Banner;
